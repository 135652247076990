import i18n from '@/View/Locale/i18n';
import { ErrorHandler, SuccessHandler } from '@/Core/Utils';
function get(client) {
    return function () {
        return client({ path: '/account-settings/products', method: 'GET' })
            .then(SuccessHandler)
            .catch(ErrorHandler(i18n.t('default-word-products')));
    };
}
export var Product = {
    get: get,
};
