export var API_URL = '/user-management/v1';
export var protocol = process.env.protocol || window.location.protocol;
export var hostname = process.env.hostname || window.location.hostname;
export var origin = process.env.origin || window.location.origin;
export var BannerBlog = {
    category: 'Open Insurance',
    title: 'Open Insurance Challenges',
    type: 'Blog',
    link: 'https://www.sensedia.com/'
};
export var BannerSeries = {
    title: 'API Play',
    episode: 'Episode 1',
    type: 'Series',
    link: 'https://br.sensedia.com/api-agile-architecture'
};
export var sensediaDocs = 'https://docs.sensedia.com/';
export var releasesNote = 'https://docs.sensedia.com/en/release-notes/Latest/';
export var sensediaBlog = 'https://br.sensedia.com/blog';
export var helpDesk = 'https://sensedia.zendesk.com/';
