import { AdaptiveGovernanceIcon, AnalyticsIcon, ApiPlatformIcon, DeveloperPortalIcon, EventsHubIcon, FlexibelActionsIcon, IntegrationsIcon, HelpIcon, OpenSolutionsIcon, ServiceMeshIcon, } from '@sensedia-ui/react';
import React from 'react';
import { IconFill } from './styles';
export var handleProductIcons = function (name) {
    var nameLowerCase = name.toLocaleLowerCase();
    if (nameLowerCase.includes('platform'))
        return React.createElement(ApiPlatformIcon, { color: "white", size: 50 });
    else if (nameLowerCase.includes('developer portal'))
        return React.createElement(DeveloperPortalIcon, { color: "white", size: 50 });
    else if (nameLowerCase.includes('governance'))
        return React.createElement(AdaptiveGovernanceIcon, { color: "white", size: 50 });
    else if (nameLowerCase.includes('service mesh'))
        return React.createElement(ServiceMeshIcon, { color: "white", size: 50 });
    else if (nameLowerCase.includes('open finance'))
        return (React.createElement("div", { style: { color: '#589B78' } },
            React.createElement(OpenSolutionsIcon, { size: 80 })));
    else if (nameLowerCase.includes('open insurance'))
        return (React.createElement("div", { style: { color: '#59BFCF' } },
            React.createElement(OpenSolutionsIcon, { size: 80 })));
    else if (nameLowerCase.includes('flexible actions'))
        return React.createElement(FlexibelActionsIcon, { color: "white", size: 50 });
    else if (nameLowerCase.includes('events hub'))
        return React.createElement(EventsHubIcon, { color: "white", size: 50 });
    else if (nameLowerCase.includes('integrations'))
        return React.createElement(IntegrationsIcon, { color: 'white', size: 60 });
    else if (nameLowerCase.includes('analytics'))
        return React.createElement(AnalyticsIcon, { color: "white", size: 50 });
    else if (nameLowerCase.includes('access control'))
        return React.createElement(IconFill, { name: "users", color: 'white', size: 50 });
    else
        return React.createElement(HelpIcon, { color: "white", size: 50 });
};
export var handleProductTheme = function (name) {
    var nameLowerCase = name.toLocaleLowerCase();
    if (nameLowerCase.includes('platform'))
        return 'apiPlatform';
    else if (nameLowerCase.includes('developer portal'))
        return 'developerPortal';
    else if (nameLowerCase.includes('governance'))
        return 'adaptiveGovernance';
    else if (nameLowerCase.includes('service mesh'))
        return 'serviceMesh';
    else if (nameLowerCase.includes('open finance'))
        return 'openFinance';
    else if (nameLowerCase.includes('open insurance'))
        return 'openInsurance';
    else if (nameLowerCase.includes('flexible actions'))
        return 'flexibleActions';
    else if (nameLowerCase.includes('events hub'))
        return 'eventsHub';
    else if (nameLowerCase.includes('integrations'))
        return 'iPaas';
    else if (nameLowerCase.includes('analytics'))
        return 'analytics';
    else if (nameLowerCase.includes('access control'))
        return 'accessControl';
    else
        return 'default';
};
export var handleProductPath = function (name) {
    var nameLowerCase = name.toLocaleLowerCase();
    if (nameLowerCase.includes('platform'))
        return {
            path: '/api-manager',
            about: 'https://br.sensedia.com/api-management-platform',
        };
    else if (nameLowerCase.includes('developer portal'))
        return {
            path: '/developer-portal',
            about: 'https://br.sensedia.com/',
        };
    else if (nameLowerCase.includes('governance'))
        return {
            path: '/adaptive-governance',
            about: 'https://br.sensedia.com/api-governance',
        };
    else if (nameLowerCase.includes('service mesh'))
        return {
            path: '/mesh',
            about: 'https://br.sensedia.com/microservices-and-service-mesh',
        };
    else if (nameLowerCase.includes('open finance'))
        return {
            path: '/admin-portal',
            about: 'https://br.sensedia.com/admin-portal',
        };
    else if (nameLowerCase.includes('flexible actions'))
        return {
            path: '/flex-actions',
            about: 'https://br.sensedia.com/flexible-actions',
        };
    else if (nameLowerCase.includes('events hub'))
        return {
            path: '/events-hub',
            about: 'https://br.sensedia.com/event-driven-architecture',
        };
    else if (nameLowerCase.includes('integrations'))
        return {
            path: '/ipaas',
            about: 'https://br.sensedia.com/',
        };
    else if (nameLowerCase.includes('analytics'))
        return {
            path: '/analytics',
            about: 'https://br.sensedia.com',
        };
    else if (nameLowerCase.includes('access control'))
        return {
            path: '/access-control',
            about: 'https://br.sensedia.com/',
        };
    else if (nameLowerCase.includes('connect catalog'))
        return {
            path: '/connect-catalog',
            about: 'https://br.sensedia.com/',
        };
    else
        return {
            path: '/',
            about: 'https://br.sensedia.com/',
        };
};
