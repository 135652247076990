import React from 'react';
import Layout from '../Theme/Layout';
import AppLoading from '../Components/AppLoading';
import { Home } from '../Pages';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
export var HomeRoutes = function () {
    return (React.createElement(BrowserRouter, { basename: '/home' },
        React.createElement(AppLoading, null,
            React.createElement(Layout, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: '/', element: React.createElement(Home, null) }))))));
};
