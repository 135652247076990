import React, { useState, useEffect, Fragment } from 'react';
import { AppLoader } from './styles';
import { getCookie } from '@/Core/Infra';
import { currentUser } from '@/Core/Main/User';
import { getTenantAlias, resolveSignInUrl } from '@/Core/Utils';
import { CSSTransition, SwitchTransition } from "react-transition-group";
var AppLoading = function (_a) {
    var children = _a.children;
    var _b = useState(false), isAuth = _b[0], setAuth = _b[1];
    useEffect(function () {
        try {
            var user = currentUser(getCookie('id_token'));
            if (!user || !user.name)
                throw new Error('ErrorTokenException');
            setAuth(true);
        }
        catch (e) {
            window.location.href = resolveSignInUrl(getTenantAlias()).concat('/logout');
        }
    }, []);
    return (React.createElement(AppLoader, null,
        React.createElement(SwitchTransition, null,
            React.createElement(CSSTransition, { in: isAuth, key: isAuth ? 'app' : 'load', timeout: 300, classNames: 'fade', unmountOnExit: true, appear: true }, isAuth ? children : React.createElement(Fragment, null)))));
};
export default AppLoading;
