import React from 'react';
import { First, Second } from './styles';
var NavFirst = function (_a) {
    var children = _a.children;
    return (React.createElement(First, null, children));
};
var NavSecond = function (_a) {
    var children = _a.children;
    return (React.createElement(Second, null, children));
};
export { NavFirst, NavSecond };
