import React from 'react';
import { CardProduct, ProductContent } from '@/View/Components/Product/styles';
import { Loader } from '@sensedia-ui/react';
import { Skeleton } from '@sensedia-ui/react';
export var ProductsLoader = function () {
    return (React.createElement(React.Fragment, null, new Array(9).fill('').map(function (_, idx) { return (React.createElement(CardProduct, { "data-testid": 'test-products-loaders', key: "loader-".concat(idx) },
        React.createElement(ProductContent, { isDisabled: true, bg: 'default' },
            React.createElement(Loader, { size: 35, border: 5 })),
        React.createElement(Skeleton, { width: 100, height: 14 }))); })));
};
