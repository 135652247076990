import jwt from 'jwt-decode';
import { getCookie } from '@/Core/Infra';
import { origin } from '@/View/Constants';
export function deleteAllTokens() {
    document.cookie.split(';').forEach(function (c) {
        var name = c.split('=')[0].trim();
        if (name.includes('token'))
            document.cookie = "".concat(name, "=;domain=sensedia-eng.com;path=/;max-age=0");
    });
}
export var getClientId = function () {
    try {
        var idToken = jwt(getCookie('id_token'));
        var accessToken = jwt(getCookie('access_token'));
        if (!!accessToken.client_id) {
            return accessToken.client_id;
        }
        if (!!idToken.aud) {
            return idToken.aud;
        }
        return '';
    }
    catch (e) {
        return '';
    }
};
export var getTenantAlias = function () {
    try {
        var idToken = jwt(getCookie('id_token'));
        if (!!idToken && !!idToken['custom:tenantAlias']) {
            return idToken['custom:tenantAlias'];
        }
        return '';
    }
    catch (e) {
        return '';
    }
};
export function resolveSignInUrl(tenantAlias) {
    if (!tenantAlias)
        return origin.replace("https://", "https://signin.");
    return origin.replace("https://", "https://".concat(tenantAlias, ".signin."));
}
