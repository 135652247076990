export var set = function (key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
};
export var get = function (key) {
    var data = localStorage.getItem(key);
    return JSON.parse(data ? data : '{}');
};
export var setCookie = function (key, value, domain) {
    if (domain === void 0) { domain = 'sensedia-eng.com'; }
    return document.cookie = "".concat(key, "=").concat(value, "; path=/; domain=").concat(domain);
};
export var getCookie = function (key) {
    var value = "; ".concat(document.cookie);
    var parts = value.split("; ".concat(key, "="));
    if (parts.length === 2)
        return parts[1].split(';')[0];
    return '';
};
