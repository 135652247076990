var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
import { HttpAdapter } from "..";
import { userLogout } from "@/Core/Main/User";
import { RefreshToken as Refresh } from "@/Core/Data";
import { getCookie, setCookie } from "../StorageAdapter";
import { getClientId, getTenantAlias, resolveSignInUrl } from "@/Core/Utils";
export var reqInterceptorFulfilled = function (_a) {
    var _b;
    var headers = _a.headers, config = __rest(_a, ["headers"]);
    if ((_b = config.url) === null || _b === void 0 ? void 0 : _b.includes('/oauth2/token'))
        return __assign(__assign({}, config), { headers: headers });
    var requestConfig = __assign(__assign({}, config), { headers: __assign(__assign({}, headers), { 'Accept-Language': 'en', 'content-type': 'application/json', authorization: "Bearer ".concat(getCookie('access_token')) }) });
    return requestConfig;
};
export var resInterceptorFulfilled = function (response) { return response; };
export function resolveDomain() {
    var items = window.location.hostname.split('.');
    var domain = window.location.hostname
        .split('.')
        .splice(items.length - 2)
        .join('.');
    return domain;
}
export var resInterceptorRejected = function (err) { return __awaiter(void 0, void 0, void 0, function () {
    var params, client, refreshToken, data_1, e_1, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                if (![401, 402, 403].includes(err.response.status)) return [3 /*break*/, 4];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                params = {
                    grant_type: 'refresh_token',
                    refresh_token: getCookie('refresh_token'),
                    client_id: getClientId(),
                };
                client = HttpAdapter({
                    url: resolveSignInUrl(getTenantAlias()),
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'tenantAlias': getTenantAlias()
                    },
                });
                refreshToken = Refresh.refresh(client);
                return [4 /*yield*/, refreshToken(params)];
            case 2:
                data_1 = (_a.sent()).data;
                Object
                    .keys(data_1)
                    .forEach(function (key) { return setCookie(key, data_1[key], resolveDomain()); });
                return [2 /*return*/, axios.request(__assign(__assign({}, err.config), { headers: __assign(__assign({}, err.config.headers), { authorization: "Bearer ".concat(data_1.access_token) }) }))];
            case 3:
                e_1 = _a.sent();
                userLogout();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/, Promise.reject(err.response)];
            case 5:
                e_2 = _a.sent();
                return [2 /*return*/, Promise.reject(err.response)];
            case 6: return [2 /*return*/];
        }
    });
}); };
axios.interceptors.request.use(reqInterceptorFulfilled);
axios.interceptors.response.use(resInterceptorFulfilled, resInterceptorRejected);
export default axios;
