import React, { useEffect, useState } from 'react';
import { Container, OtherContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@sensedia-ui/react';
import { Typography } from '@sensedia-ui/react';
import { Product } from '@/View/Components/Product';
import { ProductsLoader } from '../ProductsLoader';
import { productSort } from './index.resolvers';
var BoxStyled = {
    flex: 1,
};
export var Products = function (_a) {
    var data = _a.data;
    var t = useTranslation()[0];
    var _b = useState([]), products = _b[0], setProducts = _b[1];
    useEffect(function () {
        var accessibleProducts = data.sort(productSort).filter(function (product) {
            return (product.associatedWithCurrentUser &&
                product.name !== 'Connect Catalog');
        });
        setProducts(accessibleProducts);
    }, [data]);
    return (React.createElement(Box, { mb: 50, direction: 'column', style: BoxStyled, items: 'flex-start' },
        React.createElement(Typography, { as: "title", fontWeight: "bold", "data-testid": 'test-title-products' }, t('home-title-products')),
        React.createElement(OtherContainer, null,
            React.createElement(Container, { "data-testid": "container-products" }, !products.length ? (React.createElement(ProductsLoader, null)) : (products.map(function (product) { return (React.createElement(Product, { key: product.id, name: product.name })); }))))));
};
