import React, { useState } from 'react';
import { getCookie } from '@/Core/Infra';
import { currentUser, userLogout } from '@/Core/Main/User';
import userImg from '../../Assets/default-image.jpg';
import { UserModal } from '@sensedia-ui/layout';
export var UserAccount = function () {
    var user = useState(function () { return currentUser(getCookie('id_token')); })[0];
    var logout = function () {
        userLogout();
    };
    var goAccountSettings = function () {
        window.location.href = '/access-control/account-settings';
    };
    return (React.createElement(UserModal, { userProps: { userName: user.name, userEmail: user.email, userLevel: '', userPhoto: userImg }, editAccountLabel: 'Account Settings', logoutLabel: 'Logout', editAccount: function () {
            goAccountSettings();
        }, logout: function () {
            logout();
        }, btnItems: 'left', pl: 300 }));
};
