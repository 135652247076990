var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var HeaderContent = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   min-height: 70px;\n   background-color: ", ";\n   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n   display: flex;\n   align-items: center;\n   padding: 10px 40px;\n\n   position: sticky;\n   top: 0;\n   z-index: ", ";\n"], ["\n   min-height: 70px;\n   background-color: ", ";\n   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n   display: flex;\n   align-items: center;\n   padding: 10px 40px;\n\n   position: sticky;\n   top: 0;\n   z-index: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grayDark;
}, function (_a) {
    var theme = _a.theme;
    return theme.zIndex.sticky;
});
export var HeaderLimiter = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n   max-width: 1440px;\n\ttext-align: center;\n\tmargin: 0 auto;\n   width: 100%;\n"], ["\n   max-width: 1440px;\n\ttext-align: center;\n\tmargin: 0 auto;\n   width: 100%;\n"])));
var templateObject_1, templateObject_2;
