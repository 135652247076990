var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Icon } from '@sensedia-ui/react';
import styled, { css } from 'styled-components';
var productsProps = {
    adaptiveGovernance: '#1771C6',
    analytics: '#837FF6',
    apiPlatform: '#792FAD',
    developerPortal: '#6836BA',
    eventsHub: '#F56649',
    flexibleActions: '#AD2081',
    iPaas: '#C94B4D',
    openFinance: 'trasnparent',
    openInsurance: 'trasnparent',
    serviceMesh: '#1D807C',
    accessControl: '#9CBDDE',
    default: '#7C90C0',
};
export var CardProduct = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   position: relative;\n\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   flex-direction: column;\n   white-space: nowrap;\n"], ["\n   position: relative;\n\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   flex-direction: column;\n   white-space: nowrap;\n"])));
export var MoreDetails = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n   position: absolute;\n   top: 0;\n   left: 0;\n   display: none;\n"], ["\n   position: absolute;\n   top: 0;\n   left: 0;\n   display: none;\n"])));
export var IconFill = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n   & path {\n      fill: white;\n   }\n"], ["\n   & path {\n      fill: white;\n   }\n"])));
export var ProductContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n   display: flex;\n   width: 104px;\n   height: 104px;\n   justify-content: center;\n   align-items: center;\n   background: ", ";\n   margin-bottom: 15px;\n   border-radius: 16px;\n   position: relative;\n   overflow: hidden;\n   cursor: pointer;\n   & svg {\n      color: ", ";\n   }\n   &:hover {\n      & svg {\n         transform: scale(1.07);\n      }\n\n      ", "\n   }\n"], ["\n   display: flex;\n   width: 104px;\n   height: 104px;\n   justify-content: center;\n   align-items: center;\n   background: ", ";\n   margin-bottom: 15px;\n   border-radius: 16px;\n   position: relative;\n   overflow: hidden;\n   cursor: pointer;\n   & svg {\n      color: ", ";\n   }\n   &:hover {\n      & svg {\n         transform: scale(1.07);\n      }\n\n      ", "\n   }\n"])), function (_a) {
    var isDisabled = _a.isDisabled, bg = _a.bg, theme = _a.theme, openSolutions = _a.openSolutions;
    return isDisabled
        ? openSolutions
            ? 'transparent'
            : theme.colors.grayMediumLight
        : productsProps[bg];
}, function (_a) {
    var isDisabled = _a.isDisabled, bg = _a.bg, theme = _a.theme;
    return isDisabled
        ? theme.colors.grayMediumLight
        : productsProps[bg];
}, function (_a) {
    var isDisabled = _a.isDisabled, theme = _a.theme;
    return isDisabled && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            & ", " {\n               display: flex;\n               justify-content: center;\n               align-items: center;\n               height: 100%;\n               width: 100%;\n               background: ", ";\n            }\n         "], ["\n            & ", " {\n               display: flex;\n               justify-content: center;\n               align-items: center;\n               height: 100%;\n               width: 100%;\n               background: ", ";\n            }\n         "])), MoreDetails, theme.colors.primary);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
