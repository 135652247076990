import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@sensedia-ui/core';
import { theme } from './Theme/themeSettings';
import App from './App';
import '@/View/Locale/i18n';
var container = document.getElementById('root');
var root = createRoot(container);
root.render(React.createElement(ThemeProvider, { theme: theme },
    React.createElement(App, null)));
