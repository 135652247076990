export var Http;
(function (Http) {
    var StatusCode;
    (function (StatusCode) {
        StatusCode[StatusCode["OK"] = 200] = "OK";
        StatusCode[StatusCode["CREATED"] = 201] = "CREATED";
        StatusCode[StatusCode["NO_CONTENT"] = 204] = "NO_CONTENT";
        StatusCode[StatusCode["BAD_REQUEST"] = 400] = "BAD_REQUEST";
        StatusCode[StatusCode["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
        StatusCode[StatusCode["FORBIDDEN"] = 403] = "FORBIDDEN";
        StatusCode[StatusCode["NOT_FOUND"] = 404] = "NOT_FOUND";
        StatusCode[StatusCode["CONFLICT"] = 409] = "CONFLICT";
        StatusCode[StatusCode["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
        StatusCode[StatusCode["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
        StatusCode[StatusCode["BAD_GATEWAY"] = 502] = "BAD_GATEWAY";
    })(StatusCode = Http.StatusCode || (Http.StatusCode = {}));
})(Http || (Http = {}));
