var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTheme } from '@sensedia-ui/core';
export var theme = createTheme(function (theme) { return (__assign(__assign({}, theme), { colors: __assign(__assign({}, theme.colors), { primary: '#792FAD', primaryHover: '#8B57B0', primaryActive: '#792FAD', primaryPure: '#792FAD', primaryLight: '#F6F2FF', primaryMedium: '#8B57B0', primaryDark: '#482461', secondary: '#9F60CD', secondaryActive: '#DFBFFF', secondaryDisabled: '#62397E', secondaryPure: '#9F60CD', secondaryLight: '#DFBFFF', secondaryMedium: '#62397E', secondaryDark: '#2B0B43' }) })); });
