import i18n from 'i18next';
import en from './en.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    lng: 'en',
    resources: {
        en: en,
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
    react: {
        useSuspense: false,
    },
});
export default i18n;
