import { Http } from '@/Core/Infra/HttpAdapter/index.interface';
import { BadGateway, BadRequest, Forbidden, InternalServerError, NotFound, Unauthorized, } from '@/Core/Utils/HttpResponseHandlers/HttpErrors';
export function SuccessHandler(data) {
    return Promise.resolve(data);
}
export function ErrorHandler(name) {
    return function (error) {
        var status = error.status;
        var message = error.message;
        var options = new Map([
            [Http.StatusCode.BAD_REQUEST, BadRequest(name)],
            [Http.StatusCode.UNAUTHORIZED, Unauthorized(name)],
            [Http.StatusCode.NOT_FOUND, NotFound(name)],
            [Http.StatusCode.INTERNAL_SERVER_ERROR, InternalServerError],
            [Http.StatusCode.BAD_GATEWAY, BadGateway],
            [Http.StatusCode.FORBIDDEN, Forbidden(name)],
        ]);
        var errorType = options.get(status);
        if (errorType) {
            throw errorType(message);
        }
        throw new Error(message);
    };
}
