export var productSort = function (a, b) {
    var fa = a.name.toLowerCase(), fb = b.name.toLowerCase();
    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
};
