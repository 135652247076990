var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { scrollBar } from '../ScrollBar/styles';
export var ContainerScroll = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   flex: 1 0 auto;\n"], ["\n   flex: 1 0 auto;\n"])));
export var DashboardWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   padding: 10px 40px;\n   background: ", ";\n   height: calc(100vh - 70px);\n   overflow: auto;\n\n   ", "\n\n   @media (max-width: 991px) {\n      padding: 20px 30px;\n   }\n"], ["\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   padding: 10px 40px;\n   background: ", ";\n   height: calc(100vh - 70px);\n   overflow: auto;\n\n   ", "\n\n   @media (max-width: 991px) {\n      padding: 20px 30px;\n   }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.layerBase;
}, scrollBar);
export var ContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n   width: 100%;\n   max-width: 1440px;\n   height: 100%;\n"], ["\n   width: 100%;\n   max-width: 1440px;\n   height: 100%;\n"])));
export var Separator = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n   float: left;\n   padding: 0 5px;\n   border-left: 2px ", " solid;\n   height: 20px;\n   margin: 0 0 0 10px;\n"], ["\n   float: left;\n   padding: 0 5px;\n   border-left: 2px ", " solid;\n   height: 20px;\n   margin: 0 0 0 10px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grayLight;
});
export var ContainerHome = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n   display: flex;\n   margin-top: 20px;\n   min-height: calc(80vh - 100px);\n\n   @media (max-width: 950px) {\n      margin-top: 30px;\n      flex-direction: column;\n      justify-content: center;\n      min-height: calc(80vh - 110px);\n   }\n"], ["\n   display: flex;\n   margin-top: 20px;\n   min-height: calc(80vh - 100px);\n\n   @media (max-width: 950px) {\n      margin-top: 30px;\n      flex-direction: column;\n      justify-content: center;\n      min-height: calc(80vh - 110px);\n   }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
