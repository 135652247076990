import React from 'react';
import { useQuery } from 'react-query';
import { Links } from './Partials/Links';
import { Products } from './Partials/Products';
import { getProducts } from '@/Core/Main/Product';
import { ContainerHome } from '@/View/Theme/Layout/styles';
var BoxStyled = {
    flex: 1,
};
export var Home = function () {
    var products = useQuery('@PRODUCTS', getProducts, {
        retry: false,
        initialData: { data: [] },
    }).data;
    return (React.createElement("div", { style: BoxStyled },
        React.createElement(ContainerHome, null,
            React.createElement(Products, { data: products.data })),
        React.createElement(Links, null)));
};
