import React from 'react';
import { IconFill, Link, LinkTitle, LinksStyle, ContainerLink } from './styles';
import { DownloadIcon } from '@sensedia-ui/react';
import { useTranslation } from 'react-i18next';
import { download, helpDesk, releasesNote, sensediaBlog, sensediaDocs, } from '@/View/Constants';
import { Box } from '@sensedia-ui/react';
export var Links = function () {
    var t = useTranslation()[0];
    var openLink = function (link) {
        return window.open(link, '_blank');
    };
    return (React.createElement(ContainerLink, null,
        React.createElement(LinkTitle, { as: 'h2', "data-testid": 'test-title-links' }, t('home-title-links')),
        React.createElement(Box, { mt: 25, pb: 30, wrap: 'wrap', justify: 'flex-start' },
            React.createElement(Box, { mr: 50 },
                React.createElement(Link, { onClick: function () { return openLink(sensediaDocs); }, "data-testid": 'test-link-sensedia-docs' },
                    React.createElement(Box, { mr: 10 },
                        React.createElement(IconFill, { name: 'overview', color: 'rgb(61, 61, 61)', size: 24 })),
                    React.createElement(LinksStyle, { fontSize: '16' }, t('home-link-sensedia-docs')))),
            React.createElement(Box, { mr: 50 },
                React.createElement(Link, { onClick: function () { return openLink(releasesNote); }, "data-testid": 'test-link-releases-notes' },
                    React.createElement(Box, { mr: 10 },
                        React.createElement(IconFill, { name: 'policy', color: 'rgb(61, 61, 61)', size: 25 })),
                    React.createElement(LinksStyle, { fontSize: '16' }, t('home-link-releases-notes')))),
            React.createElement(Box, { mr: 50 },
                React.createElement(Link, { onClick: function () { return openLink(sensediaBlog); }, "data-testid": 'test-link-sensedia-blog' },
                    React.createElement(Box, { mr: 10 },
                        React.createElement(IconFill, { name: 'desktop', color: 'rgb(61, 61, 61)', size: 20 })),
                    React.createElement(LinksStyle, { fontSize: '16' }, t('home-link-sensedia-blog')))),
            React.createElement(Box, { mr: 50 },
                React.createElement(Link, { onClick: function () { return openLink(helpDesk); }, "data-testid": 'test-link-help-desk' },
                    React.createElement(Box, { mr: 10 },
                        React.createElement(IconFill, { name: 'feedback' })),
                    React.createElement(LinksStyle, { fontSize: '16' }, t('home-link-help-center')))),
            React.createElement(Box, { mr: 50 },
                React.createElement(Link, { onClick: function () { return openLink(download); }, "data-testid": 'test-link-download' },
                    React.createElement(Box, { mr: 10 },
                        React.createElement(DownloadIcon, null)),
                    React.createElement(LinksStyle, { fontSize: '16' }, t('home-link-download')))))));
};
