var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Icon, Typography } from '@sensedia-ui/react';
import styled from 'styled-components';
export var ContainerLink = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   position: relative;\n   left: 0;\n   bottom: 0;\n   height: 150px;\n\n   @media (max-width: 950px) {\n      height: 100px;\n   }\n"], ["\n   position: relative;\n   left: 0;\n   bottom: 0;\n   height: 150px;\n\n   @media (max-width: 950px) {\n      height: 100px;\n   }\n"])));
export var Link = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n   cursor: pointer;\n   display: flex;\n   align-items: center;\n   white-space: nowrap;\n   margin: 0 0 10px 0;\n\n   &:hover {\n      & p {\n         color: ", ";\n      }\n\n      & svg {\n         fill: ", ";\n         color: ", ";\n      }\n   }\n"], ["\n   cursor: pointer;\n   display: flex;\n   align-items: center;\n   white-space: nowrap;\n   margin: 0 0 10px 0;\n\n   &:hover {\n      & p {\n         color: ", ";\n      }\n\n      & svg {\n         fill: ", ";\n         color: ", ";\n      }\n   }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primaryHover;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primaryHover;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primaryHover;
});
export var IconFill = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n   & svg {\n      fill: rgb(61, 61, 61);\n   }\n"], ["\n   & svg {\n      fill: rgb(61, 61, 61);\n   }\n"])));
export var LinksStyle = styled(Typography)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n   color: rgb(61, 61, 61);\n   font-weight: 400;\n   line-height: 20px;\n   font-size: 16px;\n   letter-spacing: '';\n\n   &:hover {\n      color: rgb(139, 87, 176);\n   }\n"], ["\n   color: rgb(61, 61, 61);\n   font-weight: 400;\n   line-height: 20px;\n   font-size: 16px;\n   letter-spacing: '';\n\n   &:hover {\n      color: rgb(139, 87, 176);\n   }\n"])));
export var LinkTitle = styled(Typography)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n   color: rgb(61, 61, 61);\n   letter-spacing: 0.49px;\n   font-size: 1.125rem;\n   font-weight: 500;\n"], ["\n   color: rgb(61, 61, 61);\n   letter-spacing: 0.49px;\n   font-size: 1.125rem;\n   font-weight: 500;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
