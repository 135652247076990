import React from 'react';
import Logo from '../../../Assets/img/logo.svg';
import { UserAccount } from '../../../Components/UserAccount';
import { HeaderContent, HeaderLimiter } from './styles';
import { NavFirst, NavSecond } from '../../../../View/Theme/Components/NavBar';
import { Box } from '@sensedia-ui/react';
export var Header = function () {
    return (React.createElement(HeaderContent, null,
        React.createElement(HeaderLimiter, null,
            React.createElement(Box, { justify: 'space-between', items: 'center', "data-testid": 'new-box-header' },
                React.createElement(NavFirst, null,
                    React.createElement(Logo, { "data-testid": 'test-logo' })),
                React.createElement(NavSecond, null,
                    React.createElement(UserAccount, null))))));
};
