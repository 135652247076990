import { Http } from '../../../Core/Infra/HttpAdapter/index.interface';
import { BadGateway, BadRequest, Forbidden, InternalServerError, NotFound, Unauthorized, } from '../../../Core/Utils/HttpResponseHandlers/HttpErrors';
export function SuccessHandler(data) {
    return Promise.resolve(data);
}
export function ErrorHandler(name) {
    return function (error) {
        var status = error.status;
        var message = error.message;
        var options = [
            [Http.StatusCode.BAD_REQUEST, BadRequest(name)],
            [Http.StatusCode.UNAUTHORIZED, Unauthorized(name)],
            [Http.StatusCode.NOT_FOUND, NotFound(name)],
            [Http.StatusCode.INTERNAL_SERVER_ERROR, InternalServerError],
            [Http.StatusCode.BAD_GATEWAY, BadGateway],
            [Http.StatusCode.FORBIDDEN, Forbidden(name)],
        ];
        var errorType = match(status, options);
        if (errorType) {
            throw new errorType(message);
        }
        throw new Error(message);
    };
}
function match(condition, options) {
    var result;
    options.forEach(function (option) {
        if (option[0] === condition) {
            result = option[1];
            return;
        }
    });
    return result;
}
