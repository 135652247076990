import i18n from '@/View/Locale/i18n';
import { ErrorHandler, SuccessHandler } from '@/Core/Utils';
function refresh(client) {
    return function (params) {
        return client({
            path: '/oauth2/token',
            method: 'POST',
            data: new URLSearchParams(params).toString()
        })
            .then(SuccessHandler)
            .catch(ErrorHandler(i18n.t('default-word-products')));
    };
}
export var RefreshToken = {
    refresh: refresh,
};
