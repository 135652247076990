var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var BaseError = /** @class */ (function (_super) {
    __extends(BaseError, _super);
    function BaseError(errorData) {
        var _this = _super.call(this, errorData.message) || this;
        _this.name = errorData.name;
        _this.stack = errorData.stack;
        return _this;
    }
    return BaseError;
}(Error));
export function BadRequest(name, data) {
    return function () {
        return new BaseError({
            name: 'BadRequest',
            message: name ? name : 'Bad Request',
            stack: data ? data : undefined,
        });
    };
}
export function Unauthorized(name) {
    return function () {
        return new BaseError({
            name: 'Unauthorized',
            message: name ? "".concat(name, " is not authorized") : 'Unauthorized',
        });
    };
}
export function Forbidden(name) {
    return function () {
        return new BaseError({
            name: 'Forbidden',
            message: name ? "".concat(name, " is not authorized") : 'Forbidden',
        });
    };
}
export function NotFound(name) {
    return function () {
        return new BaseError({
            name: 'NotFound',
            message: name ? "".concat(name, " is not found") : 'Not Found',
        });
    };
}
export function InternalServerError() {
    return new BaseError({
        name: 'InternalServerError',
        message: 'Internal Server Error',
    });
}
export function BadGateway() {
    return new BaseError({
        name: 'BadGateway',
        message: 'Bad Gateway',
    });
}
