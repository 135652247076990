var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var AppLoader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  left: 0px;\n  top: 0px;\n  width: 100%;\n  height: 100%;\n  background: ", ";\n   \n  .fade-enter {\n    opacity: 0.01;\n  }\n\n  .fade-enter.fade-enter-active {\n    opacity: 1;\n    transition: opacity 400ms ease-in;\n  }\n\n  .fade-exit {\n    opacity: 1;\n  }\n\n  .fade-exit.fade-exit-active {\n    opacity: 0.01;\n    transition: opacity 400ms ease-in;\n  }\n"], ["\n  position: fixed;\n  left: 0px;\n  top: 0px;\n  width: 100%;\n  height: 100%;\n  background: ", ";\n   \n  .fade-enter {\n    opacity: 0.01;\n  }\n\n  .fade-enter.fade-enter-active {\n    opacity: 1;\n    transition: opacity 400ms ease-in;\n  }\n\n  .fade-exit {\n    opacity: 1;\n  }\n\n  .fade-exit.fade-exit-active {\n    opacity: 0.01;\n    transition: opacity 400ms ease-in;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grayDark;
});
var templateObject_1;
