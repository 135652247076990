import React, { useMemo } from 'react';
import { Typography } from '@sensedia-ui/react';
import { useTranslation } from 'react-i18next';
import { handleProductPath, handleProductIcons, handleProductTheme, } from './index.resolvers';
import { CardProduct, MoreDetails, ProductContent, } from './styles';
export var Product = function (_a) {
    var name = _a.name, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var t = useTranslation()[0];
    var Icon = useMemo(function () { return handleProductIcons(name); }, [name]);
    var theme = useMemo(function () { return handleProductTheme(name); }, [name]);
    var links = useMemo(function () { return handleProductPath(name); }, [name]);
    var redirect = function () {
        if (disabled)
            return window.open(links.about, '_blank');
        return (window.location.href = links.path);
    };
    return (React.createElement(CardProduct, null,
        React.createElement(ProductContent, { isDisabled: disabled, bg: theme, openSolutions: name.toLowerCase().includes('open'), onClick: redirect, "data-testid": name },
            Icon,
            disabled && (React.createElement(MoreDetails, { "data-testid": 'test-about-product' },
                React.createElement(Typography, { fontSize: '14', color: 'white' }, t('default-message-more-details'))))),
        React.createElement(Typography, { fontSize: '14', "data-testid": "product-".concat(name) }, name)));
};
