import React from 'react';
import { Header } from '../Components/Header';
import { LoaderProgress } from '@sensedia-ui/react';
import { ContainerScroll, ContentWrapper, DashboardWrapper } from './styles';
var Layout = function (_a) {
    var children = _a.children;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(LoaderProgress, { loading: false }),
        React.createElement(ContainerScroll, null,
            React.createElement(DashboardWrapper, null,
                React.createElement(ContentWrapper, null, children)))));
};
export default Layout;
