import React from 'react';
import userImg from '../../Assets/default-image.jpg';
import { UserModal } from '@sensedia-ui/layout';
import { SensediaIAM } from '@sensedia-iam/auth';
var user = SensediaIAM.me();
export var UserAccount = function () {
    var logout = function () {
        SensediaIAM.logout();
    };
    var goAccountSettings = function () {
        window.location.href = '/access-control/account-settings';
    };
    return (React.createElement(UserModal, { userProps: { userName: user.name, userEmail: user.email, userLevel: '', userPhoto: userImg }, editAccountLabel: 'Account Settings', logoutLabel: 'Logout', editAccount: function () {
            goAccountSettings();
        }, logout: function () {
            logout();
        }, btnItems: 'left', pl: 300 }));
};
