import React from 'react';
import GlobalStyles from '../View/Theme/globalStyle';
import { HomeRoutes as Routes } from './Routes';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '@/Core/Infra/ReactQuery';
var App = function () {
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(Routes, null),
        React.createElement(GlobalStyles, null)));
};
export default App;
