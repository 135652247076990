import jwt from 'jwt-decode';
import { deleteAllTokens, getTenantAlias, resolveSignInUrl } from '@/Core/Utils/Commons';
function currentUser() {
    return function (token) {
        try {
            var decode = jwt(token);
            return {
                name: decode['name'],
                email: decode['email'],
                username: decode['cognito:username']
            };
        }
        catch (e) {
            return {
                name: '',
                email: '',
                username: ''
            };
        }
    };
}
function logout() {
    return function () {
        var tenantAlias = getTenantAlias();
        deleteAllTokens();
        window.location.href = resolveSignInUrl(tenantAlias).concat('/logout');
    };
}
export var User = {
    currentUser: currentUser,
    logout: logout,
};
